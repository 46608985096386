:root {
  --swiper-navigation-size: 1.875rem;
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  bottom: 0;
  width: 1.156rem;
  height: 1.875rem;
  margin-top: calc(0px - (var(--swiper-navigation-size)));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00002e;
  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &:after {
    font-size: 1.875rem;
    font-weight: 900;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  &:after {
    content: url('../assets/images/icons/careers/working-with-us/material-navigate-prev.svg');
  }
  left: 40%;
  right: auto;
}
@media (max-width: 30em) {
  .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  &:after {
    content: url('../assets/images/icons/careers/working-with-us/material-navigate-prev.svg');
  }
  left: 40%;
  right: auto;
}
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  &:after {
    content: url('../assets/images/icons/careers/working-with-us/material-navigate-next.svg');
  }
  right: 40%;
  left: auto;
}
.swiper-button-lock {
  display: none;
}
@media (max-width: 30em) {
 .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  &:after {
    content: url('../assets/images/icons/careers/working-with-us/material-navigate-next.svg');
  }
  right: 40%;
  left: auto;
}
}