@font-face {
  font-family: 'Exo';
  src: url('../assets/fonts/Exo-Regular.ttf') format('truetype'),
    url('../assets/fonts/Exo-Regular.woff') format('woff'),
    url('../assets/fonts/Exo-Regular.eot') format('eot');
  font-weight: normal;
}

@font-face {
  font-family: 'Exo';
  src: url('../assets/fonts/Exo-Bold.ttf') format('truetype'),
    url('../assets/fonts/Exo-Bold.woff') format('woff'),
    url('../assets/fonts/Exo-Bold.eot') format('eot');
  font-weight: bold;
}

@font-face {
  font-family: 'Exo';
  src: url('../assets/fonts/Exo-Italic.ttf') format('truetype'),
    url('../assets/fonts/Exo-Italic.woff') format('woff'),
    url('../assets/fonts/Exo-Italic.eot') format('eot');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Exo';
  src: url('../assets/fonts/Exo-MediumItalic.ttf') format('truetype'),
    url('../assets/fonts/Exo-MediumItalic.woff') format('woff'),
    url('../assets/fonts/Exo-MediumItalic.eot') format('eot');
  font-style: italic;
  font-weight: 500;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  background-repeat: no-repeat;
  font-family: 'Exo', sans-serif;
}

html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

video {
  object-fit: cover;
}

button {
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 1.875rem;
}

h4 {
  font-size: 1.625rem;
}

h5 {
  font-size: 1.375rem;
}

p,
li {
  font-size: 1.188rem;
}

a {
  text-decoration: none;
}

strong,
b {
  font-weight: 700;
}

body {
  width: 100%;
  height: 100%;
  color: #00002e;
  margin: 0;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #ffff;
  text-decoration: 'none';
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
}

@media print {
  body {
    background-color: #fff;
  }
}

body::backdrop {
  background-color: #fff;
}

@media (max-device-width: 35.625em) {
  h1 {
    font-size: 2.43rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.18rem;
  }

  p {
    font-size: 1.18rem;
  }

  li {
    font-size: 1.18rem;
  }
}

@media (max-width: 47.938em) {
  h1 {
    font-size: 2.43rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.18rem;
  }

  li {
    font-size: 1.18rem;
  }
}

@media only screen and (max-height : 40em) and (min-device-width : 67.5em) and (orientation: landscape) {
  h1 {
    font-size: 2.4rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.18rem;
  }

  h5 {
    font-size: 1.1rem;
  }

  p {
    font-size: 1.1rem;
  }

  li {
    font-size: 1.18rem;
  }
}

@media only screen and (max-height : 30em) and (min-device-width : 67.5em) and (orientation: landscape) {
  h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-device-height : 43.75em) and (min-device-width :67.5em) and (orientation: landscape) {
  h1 {
    font-size: 2.43rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.18rem;
  }

  p {
    font-size: 1.1rem;
  }

  li {
    font-size: 1.1rem;
  }
}

@media (max-width: 48em) {
  html {
    font-size: 75%;
    line-height: 80%;
  }
}

@media (min-width: 48em) {
  html {
    font-size: 85%;
    line-height: 90%;
  }
}

@media (min-width: 85.375em) {
  html {
    font-size: 90%;
    line-height: 95%;
  }
}

@media (min-width: 120em) {
  html {
    font-size: 100%;
    line-height: 105%;
  }
}

@media (min-width: 160em) {
  html {
    font-size: 105%;
    line-height: 110%;
  }
}

@media (min-width: 200em) {
  html {
    font-size: 110%;
    line-height: 115%;
  }
}

@media (min-width: 400em) {
  html {
    font-size: 130%;
    line-height: 135%;
  }
}

#mobile-nav::-webkit-scrollbar {
  display: none;
}

@media (max-width: 112.5em) {
  .swiper-slide {
    width: 35%;
  }
}

@media (max-width: 87.5em) {
  .swiper-slide {
    width: 35%;
  }
}

@media (max-width: 67.5em) {
  .swiper-slide {
    width: 52.45%;
  }
}

@media (max-width: 30em) {
  .swiper-slide {
    width: 63.7%;
  }
}

input[type="checkbox"]:checked~#checkbox-button {
  background-color: #00CCFF;
  border: none;
}

input[type="checkbox"]:checked~#checkbox-button:after {
  display: block;
}

#checkbox-button:after {
  content: "";
  position: absolute;
  display: none;
  right: 5px;
  bottom: 2px;
  width: 10px;
  height: 100%;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 100px white inset;
  border: 1px solid #00002E;
  transition: background-color 5000s ease-in-out 0s;
}

@keyframes rotate {
  0% {
    transform: rotate(360deg);
  }
}

@media (max-device-width:67.5em) {
  #contacts {
    margin-bottom: 10rem;
  }
}